.container, .text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
}

.text-container {
	max-width: 750px;
}

.container {
	max-width: 1140px;

	&.max-container {
		max-width: 100%;
		padding: 0;
	}
}

header {
	color: #fff;
	padding: 20px 0;
	background: $brand-color; /* Old browsers */
	background: linear-gradient(to bottom, $brand-color 0%, $middle-gradient-color 100%) no-repeat $brand-color;

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 1.7em;
		line-height: 0;

		a {
			display: inline-block;
		}

		img {
			display: block;
			width: auto;
		}
	}
}

.content {
	background: #fff;
	padding: 1px 0 0 0;
	position: relative;
}

.screenshot{
	max-width: 100%;
	height: auto;
	display: block;
	border-radius: 2px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 0 0 0;
	position: relative;
	box-shadow: 1px 1px 8px 0px rgba(50, 50, 50, 0.75);
}

section {
	padding: 100px 0;
}

section + section {
	padding-top: 0;
}

.subtext {
	margin-top: 10px;
	text-align: center;
}


.cta {
	margin: 60px 0;
}

.page h2 {
	text-align: center;
}

blockquote {
	padding: 18px 25px;
	margin: 0;
	quotes: "\201C""\201D""\2018""\2019";
	font-style: italic;

	.author {
		display: block;
		font-weight: bold;
		margin: 10px 0 0 0;
		font-size: .85em;
		font-style: normal;
	}

	p {
		display: inline;
	}
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.square-image {
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 25px auto 0 auto;
	position: relative;
	border-radius: 200px;

	img {
		position: absolute;
		left: -1000%;
		right: -1000%;
		top: -1000%;
		bottom: -1000%;
		margin: auto;
		width: 300px;
	}
}

.testimonial-image {
	width: 64px;
	height: 64px;
	overflow: hidden;
	margin: 25px auto 0 auto;
	position: relative;

	img {
		position: absolute;
		left: -1000%;
		right: -1000%;
		top: -1000%;
		bottom: -1000%;
		margin: auto;
	}
}

@media #{$desktop} {
	.testimonial-image {
		margin-right: 40px;
		margin-top: 40px;
	}
}

.page {
	margin-bottom: 0;
	padding-bottom: 80px;
}

.center-text {
	text-align: center;
}

.editor-link {
 	display: none;
	margin-top: 0;
	.btn {
		border: 0;
		border-radius: 2px;
		width: 100%;
		max-width: 500px;
		box-sizing: border-box;
		font-size: 2rem;
		text-decoration: none;
		padding: 10px 15px;
		margin: 0;
		font-size: 18px;
		cursor: pointer;
		background-color: #f7e064;
		color: #333;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #f4d525;
			color: #333;
		}
	}

}

.wrapper {
	position: relative;
}

.bulletpoint-section {
	background-color: #ffffff;
}

.bulletpoints-container {
	padding-left: 0px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

@media #{$desktop} {
	.bulletpoints-container {
		flex-direction: row;
	}
}


.bulletpoints-box1 {
	flex: 1;
	order: 1;
	flex-shrink: 0;
	justify-content: center;
}

.bulletpoints-box2 {
	flex: 1;
	order: 2;
	flex-shrink: 0;
	justify-content: center;
}


.bulletpoint-image {
	margin-right:10px;
	width: 24px;
	padding-bottom: 20px;
}

@media #{$desktop} {
	.bulletpoint-image {
		padding-bottom: 20px;
	}
}


.bulletpoint-text {
	font-size: 1.1em;
  color: #666;
  font-weight: 300;
  line-height: 1.4em;
	font-family: "Helvetica Neue", "Helvetica", "Arial";
}

.bulletpoint-text-strong {
  color: #000000;
}

.wine-row-left {
    display: flex;
    flex-direction: row;
    height: 50px;
    
    justify-content: space-around;
    margin-bottom: 100px;
    min-width: 400px;
}

@media #{$desktop} {
	.wine-row-left {
		padding: 0px;
		margin-bottom: 75px;
		min-width: 480px;
	}
}


.wine-row-right {
    display: flex;
    flex-direction: row;
    height: 50px;

	justify-content: space-around;
	margin-bottom: 100px;
	min-width: 400px;
}

@media #{$desktop} {
	.wine-row-right {
		padding: 0px;
		margin-bottom: 75px;
		min-width: 480px;
	}
}

.wine-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.feature-container {
	height: 110px;
	font-size: 10px;
}
.feature-img-wrapper {
	border-radius: 50%;
	background: #97B6F9;
	width: 42px;
	height: 42px;
	float:left;
	margin-top: 8px;
}
.feature-img {
	width: 24px;
	margin-left: 9px;
	margin-top: 9px;
}
.feature-text-wrapper {
	float: left;
	margin-left: 10px;
	height: 105px;
	width: 300px;
}
@media #{$desktop} {
	.feature-text-wrapper {
		width: 360px;
	}
}
.feature-heading {
	margin-top: 4px;
	margin-bottom: 0px;
	color: #000000;
	font-size: 20px;
}
.feature-subtext {
	padding-top: 0px;
	margin: 0 0 0 0;
	font-size: 18px;
}


.bar2
{
  bottom: 200px;
  left: 250px;
  height: 100px;
}

#tooltip
{
  width: 150px;
  height: auto;
  min-height: 100px;
  position: absolute;
  display:none;
  border: 1px solid black;
  text-align: center;
	z-index: 10; // important!
	background-color: #FFFFFF;
	font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial";
	font-size: 12px;
	border-radius: 10px;
	box-shadow: 0 0.225rem 0.35rem rgba(0,0,0,0.275);
	padding-left: 10px;
	padding-right: 10px;
	p {
		color: #000000;
	}
}

@media #{$desktop} {
	#tooltip {
		width: 300px;
		font-size: 16px;
	}
}


.imgwrapper {
	position:relative;
}

.img1box1 {
	 position:absolute;
	 top:10px;
	 left:10px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}

.img1box2 {
	 position:absolute;
	 top:4px;
	 left:9px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}

.img2box1 {
	 position:absolute;
	 top:10px;
	 left:10px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}

.img3box1 {
	 position:absolute;
	 top:10px;
	 left:10px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}

.img3box2 {
	 position:absolute;
	 top:4px;
	 left:9px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}

.img3box3 {
	 position:absolute;
	 top:4px;
	 left:9px;
	 width:0px;
	 height:0px;
	 border:2px solid #4182e499;
	 background-color:transparent;
}


.issue-item-container {
	background-color: #EFEFEF;
	margin: 0;
	padding: 0;
	border-radius: 25px;
	border: 2px solid #BBBBBB;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
    text-overflow: ellipsis;
	justify-content: center;
	padding-top: 50px;
	padding-bottom: 50px;
}
.issue-item {
	background-color: #FFFFFF;
	border: 2px solid #BBBBBB;
	border-radius: 15px;
	width: 300px;
	height: 200px;
	box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
	margin: 10px;
	margin-bottom: 40px;
	p {
		font-size: 1.0em;
	}
}
.issue-item:hover {
	box-shadow: 0 0.225rem 0.35rem rgba(0,0,0,0.275);
}
.issue-item-title {
	margin: 10px;
	height: 124px;
	max-height: 124px;
}
.issue-item-icon {
	border-radius: 25px;
	width: 48px;
	height: 48px;
	background-color: #555555;
	float: top;
	margin-left: 126px;
	margin-top: -24px;
}

.issue-item-link-img-wrapper {
	border-radius: 2px;
	float: right;
	width: 24px;
	height: 24px;
	background-color: #FFFFFF;
	margin-top: -15px;
	margin-right: 10px;
}

.issue-item-link-img {

}

.issue-item-tags-container {
	float: left;
	height: 30px;
	margin-top: -50px;
	margin-left: 10px;
}

.issue-item-tags {
	background-color: #777777;
	border-radius: 25px;
	float:left;
	margin-right: 10px;
	margin-bottom: 10px;
	font-size: 0.9em;
	padding-bottom: 2px;
	padding-right: 8px;
	padding-left: 8px;
	p {
		font-size: 0.9em;
		margin: 2px;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.issue-item-author {
	height: 30px;
	float: right;
	margin-right: 10px;
	font-size: 0.9em;
}
